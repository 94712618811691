import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import * as sections from "../../components/sections"
import Fallback from "../../components/fallback"

export default function Job(props) {
  const { jobPage } = props.data

  return (
    <Layout {...jobPage}>
      {jobPage.blocks.map((block) => {
        const { id, blocktype, ...componentProps } = block
        const Component = sections[blocktype] || Fallback
        const siblingIndex = jobPage.blocks
          .filter((b) => b.blocktype === blocktype)
          .findIndex((i) => i.id === id)
        return (
          <Component key={id} {...componentProps} siblingIndex={siblingIndex} />
        )
      })}
    </Layout>
  )
}

export const query = graphql`
  {
    jobPage(node_locale: { eq: "en-US" }) {
      id
      title
      description
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        node_locale
        ...HomepageHeroContent
        ...HomepageBannerContent
      }
    }
  }
`
